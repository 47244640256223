import React, { useRef, useEffect } from 'react';
import poster1 from '../assets/img/newhomepage/video1.png';
import video_two from '../assets/videos/secondvideo.mp4';

const LazyVideo = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    videoRef.current.play();
                } else {
                    videoRef.current.pause();
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5  // Adjust threshold to when you want the video to start playing
        });

        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };
    }, []);

    return (
        <div className="video-container">
            <video ref={videoRef} className="responsive-video" poster={poster1} controls>
                <source src={video_two} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <p className="video-caption">Explore how Education Capsule serves our community</p>
        </div>
    );
};

export default LazyVideo;
