import React from "react";


import model from '../assets/img/services/model.png'

class SufegaurdingPolicy extends React.Component {
    render() {
        return (
            <>
                <section id="examhelp-sec-5">
                    <div className="container">
                        <h1>Safeguarding Policy</h1><br></br>
                        <div className="outer">
                            <div className="row">

                                <div className="col-lg-6 col-12 col-md-6">
                                    <div className="homework-data terms">
                                        <h3>What is safeguarding?</h3>
                                        <p>Safeguarding means the measures taken to protect the health, well-being, and human rights of individuals which allow them to live abuse-free life.<br></br>
                                            We highly prioritize safeguarding and have set up policies to meet the standards set by NSPCC regarding safeguarding. The well-being of the children we work with is of utmost importance. We understand that children and young people should never experience any kind of abuse or mistreatment. All children have a right to equal protection from all types of abuse and harm irrespective of their religion, community, gender, belief, age, ailment, or sexual orientation.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 col-md-6">
                                    <div className="homework-data terms">
                                        <p> We hire tutors from trustful resources after a complete background check. Tutors are also obliged to hand over government-authorized DBS. DBS checks are repeated every two years. Safeguarding training is part of our initial tutor training. Only tutors scoring 100% score in our internal safeguarding test are further considered for tutoring.</p>
                                        <p>All the sessions are recorded and saved in our portal which can also be accessed by the guardian of the student. The sessions are monitored for quality weekly. If we feel that the student is being threatened or is in any danger or harm, we report it immediately to the concerned person. </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 col-md-6">
                                    <div className="icon-outer-2">
                                        <img src={model} alt="Model" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-12 col-md-6">
                                    <div className="homework-data-4">
                                        Contact Details for Safeguarding<br></br>
                                        Our Designated Safeguarding Lead can be contacted at<br></br>
                                        <a href="tel:+447776757225" style={{ color: '#000' }}> +447776757225 </a><br></br>
                                        <a href="mailto:support@educationcapsule.com" style={{ color: '#000' }}> support@educationcapsule.com</a>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>
            </>
        )
    }
}

export default SufegaurdingPolicy