import React, { useEffect, useState } from 'react';
import homework from '../assets/img/services/new/homework.png'
import * as Constant from '../components/Constant'
import { Link, useParams } from 'react-router-dom';

export default function BlogDetails() {
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();

  // Fetch the blog details on component mount using fetch
  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(`${Constant.BlogDetails}/${slug}` );
        
        // Check if the response is successful (status 200-299)
        if (!response.ok) {
          throw new Error('Failed to fetch blog details');
        }

        const data = await response.json(); // Parse the JSON data
        console.log('yuyyuyu',data)
        setBlog(data.data);
      } catch (err) {
        setError('Failed to fetch blog details');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, []);

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="" style={{ marginTop: '-1px' }}>

        <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '400px',background:'#F8FAFC' }}>
                 <h1 className="display-3 font-weight-bold">Blogs</h1>
                 <div className="d-inline-flex text-white">
                   <p className="m-0">
                     <Link className="text-dark" to="/">
                       Home
                     </Link>
                   </p>
                   <p className="m-0 px-2">/</p>
                   <p className="m-0 text-dark">Blog</p>
                 </div>
               </div>

      <div className=' text-center mt-5'>
        <h1>Blog Details</h1>
        </div>
      <div className=' w-100 d-flex justify-content-center mt-5'>
      <div className=' flex-md-row shadow-lg p-5 col-sm-10 col-xs-10 col-md-7 col-lg-7 col-xl-7' style={{height:"auto"}}>
  <div className='col-xl-5 col-lg-5 col-md-5 col-sm-10 col-10 mx-auto'>
    <img src={blog?.image} className='w-100' style={{marginTop:"30px"}}/>
  </div>
  <div className='w-100 mt-4 mt-md-0'>
    <h1 className="text-3xl font-bold text-gray-800 mt-5 ms-5">{blog?.title}</h1>
    <p className="text-sm text-gray-500 mt-2 ms-5">
      {new Date(blog?.created_at).toLocaleDateString()}
    </p>
    <div className="mt-2">
      <p className="text-lg text-gray-700 ms-5">{blog?.description}</p>
    </div>
  </div>
</div>
                                                   
      </div>
    </div>
  );
}
