import React,{useState,useEffect} from "react";
import '../components/home.css'

import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2';

import math from '../assets/img/newhomepage/maths.jpg'
import seven from '../assets/img/newhomepage/seven_exam.png'
import eleven from '../assets/img/newhomepage/eleven_exam.png'
import thirteen from '../assets/img/newhomepage/thirteen_exam.png'
import computing from '../assets/img/newhomepage/computing.jpg'
import science from '../assets/img/newhomepage/science.jpg'
import english from '../assets/img/newhomepage/eng.jpg'
import stas from '../assets/img/newhomepage/stas.png'
import trust_pilot from '../assets/img/trust_pilot.png'

import signup from '../assets/img/newhomepage/sign_up.png'
import request_demo from '../assets/img/newhomepage/request_demo.png'
import start_session from '../assets/img/newhomepage/start_session.png'

import Carousel from 'react-bootstrap/Carousel';

import one_to_one from '../assets/img/newhomepage/one_to_one.png'
import round_clock from '../assets/img/newhomepage/round_clock.png'
import video_lession from '../assets/img/newhomepage/video_lession.png'
import practice_quiz from '../assets/img/newhomepage/practic_quiz.png'
import reviewgoogle from '../assets/img/newhomepage/googlereview1.png'
import LazyVideo from "./LazyVideo";
import LazyVideo2 from "./LazyVideo2";
import LazyVideo3 from "./LazyVideo3";
import FAQComponent from './FAQComponent';

import * as Constant from './Constant'
import '../assets/css/stylenew.css'

import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';


class Homepagenew extends React.Component {

    constructor() {
        super();
        this.state = {
            country_id: '',
            name: '',
            email: '',
            phone: '',
            message: '',
            level: '',
            tabone: true,
            tabtwo: false,
            tabthree: false,
            tabfour: false,
            tabfive: false,
            subject:'',

            isLoading: false,

            nameError: null,
            emailError: null,
            phoneError: null,
            captchaText: '',
            userInput: '',
            isCaptchaValid: false,
            answer: '',
            isCorret: '',

            CountryList: [],
            SubjectList: [],
            subject_id: '',
            level_id: '',
            LevelList: [],
            level_idError: null,
            subject_idError: null,
            country_idError: null,
            selectedSubjectId: '',
            selectedSubject:''

        }

        this.recaptchaRef = React.createRef();
        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangeLevel = this.handleChangeLevel.bind(this);
    }



    onSubmit = () => {
        if (this.validate()) {
            this.setState({
                nameError: null,
                emailError: null,
                phoneError: null,
            })
            this.onEnrollDemo();
        }
    }

    validate = () => {
        let nameError = '';
        let emailError = '';
        let phoneError = '';
        let level_idError = '';
        let subject_idError = '';
        let country_idError = '';


        if (!this.state.name) {
            nameError = "Please Enter Name"
        }

        var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
        if (!this.state.email) {
            emailError = "Email Address cannot be empty.";
        } else {
            if (!reg.test(this.state.email)) {
                emailError = "Please enter a valid Email Address.";
            }
        }

        if (this.state.level.length == 0) {
            level_idError = "Please select level"
        }



        if (this.state.country_id.length == 0) {
            country_idError = "Please select country"
        }

        if (!this.state.phone) {
            phoneError = "Please Enter Contact Number"
        } else {
            if (this.state.phone.length <= 7) {
                phoneError = "Phone number cannot be less than eight digit"
            }
        }

        if (level_idError || subject_idError || nameError || emailError || phoneError || country_idError) {
            this.setState({
                level_idError,
                subject_idError,
                nameError,
                emailError,
                phoneError,
                country_idError
            });

            return false;
        }

        return true;
    }

    onEnrollDemo = async () => {
        this.setState({ isLoading: true })

        const recaptchaValue = await this.recaptchaRef.current.executeAsync();
        const formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("email", this.state.email);
        formData.append("phone", this.state.phone);
        formData.append("message", this.state.message);
        formData.append("country_id", this.state.country_id);
        formData.append("subject", this.state.selectedSubject);
        formData.append("level", this.state.level);
        formData.append("recaptchaToken", recaptchaValue);

        await fetch(Constant.FreeDemo, {
            method: 'POST',
            body: formData,
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({ isLoading: false })
                    this.setState({
                        name: '',
                        email: '',
                        phone: '',
                        message: '',
                        country_id: '',
                        subject_id: '',
                        level: ''

                    })

                    toast.success('Your request forwarded successfully..', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    this.setState({ isLoading: false })
                }
            })
    }

    handleChangeLastName = event => {
        const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
        this.setState({ name: result })
    };

    handleChangeSubject = (e) => {
        console.log("subject_id Selected!!", e.target.value);
        this.setState({ subject_id: e.target.value });
        this.getLevel(e.target.value);
    }

    handleChangeLevel = (e) => {
        console.log("level Selected!!", e.target.value);
        this.setState({ level: e.target.value });
    }

    handleChangePhone = event => {
        const result = event.target.value.replace(/[^0-9]/gi, '');
        this.setState({ phone: result })
    };



    ShowTab = (value) => {
        if (value === 'tabone') {
            this.setState({ tabone: true, tabthree: false, tabtwo: false, tabfour: false, tabfive: false })
        } else if (value === 'tabtwo') {
            this.setState({ tabtwo: true, tabthree: false, tabone: false, tabfour: false, tabfive: false })
        } else if (value === 'tabthree') {
            this.setState({ tabtwo: false, tabthree: true, tabone: false, tabfour: false, tabfive: false })
        }
        else if (value === 'tabfour') {
            this.setState({ tabtwo: false, tabthree: false, tabone: false, tabfour: true, tabfive: false })
        }
        else {
            this.setState({ tabtwo: false, tabthree: false, tabone: false, tabfour: false, tabfive: true })
        }
    }

    componentDidMount = () => {
        this.getSubject();
        this.getCountry();
    }

    getCountry = async () => {
        await fetch(Constant.getCountry, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    // console.log(data.data)
                    this.setState({ CountryList: data.data })
                }
            })
    }

    getSubject = async () => {

        await fetch(Constant.getSubject, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false,
                        SubjectList: data.data
                    })
                } else {
                    console.log('subject not available')
                }
            })
    }

    getLevel = async (value) => {

        await fetch(Constant.getLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        LevelList: data.data,
                        isLoading: false
                    })
                }
            })
    }

    handleSubjectChange = (selectedId) => {
        // Update the selected subject ID in state
        this.setState({ selectedSubjectId: selectedId });
    }

    handleSubmit = () => {
        const { selectedSubjectId } = this.state;
        if (selectedSubjectId) {
            // Redirect to /contact-us with subject ID as a query parameter
            window.location.href = `/contact-us?subject_id=${selectedSubjectId}`;
        } else {
            alert("Please select a subject before submitting.");
        }
    }

    handleSubjectChange = (subjectId) => {
        const selectedSubject = this.state.SubjectList.find(subject => subject.id === subjectId);
        if (selectedSubject) {
            this.setState({ selectedSubject: selectedSubject.title });
        }
    }


    render() {
        var userId = localStorage.getItem('userId')
        var roleId = localStorage.getItem('roleId')
        if (userId === null) {
            return (
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                            </div>
                            <div className="col-md-4 learning_form_responsive">
                                <div className="learning-form freedomestyle">
                                    <h2>Hire a Tutor</h2>
                                    <p>Learn with our Best Online Tutors</p>
                                    <div className="slider-form-outer">
                                        <div className="form-group">
                                            <input name="name" autoComplete="on" value={this.state.name} onChange={this.handleChangeLastName} type="text" className="form-control" placeholder="Enter Your Name" id="name" />
                                            {!!this.state.nameError && (
                                                <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                                    {this.state.nameError}
                                                </text>
                                            )}

                                            <input name="phone" autoComplete="on" value={this.state.phone} onChange={this.handleChangePhone} type="text" className="form-control" placeholder="Enter Your Phone Number" id="phone" maxLength="11" />
                                            {!!this.state.phoneError && (
                                                <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                                    {this.state.phoneError}
                                                </text>
                                            )}

                                            <select name="country_id" id="country_id" value={this.state.country_id} onChange={(event) => this.setState({ country_id: event.target.value })} className="form-control country_dropdown mt-1">
                                                <option value="">Select Country</option>
                                                {
                                                    this.state.CountryList.map((item, i) => (
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                            {!!this.state.country_idError && (
                                                <text style={{ color: "#000", marginLeft: "10px" }}>
                                                    {this.state.country_idError}
                                                </text>
                                            )}

                                            <input name="email" value={this.state.email} onChange={(event) =>
                                                this.setState({ email: event.target.value })
                                            } type="email" autoComplete="on" className="form-control" placeholder="Email Address" id="email" />
                                            {!!this.state.emailError && (
                                                <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                                    {this.state.emailError}
                                                </text>
                                            )}


                                            <SearchDropdown2
                                                SubjectList={this.state.SubjectList}
                                                onSubjectChange={this.handleSubjectChange}
                                            />

                                            {/* <select name="subject_id" id="subject_id" onChange={this.handleChangeSubject} value={this.state.subject_id} className="form-control country_dropdown mt-1">
                                                <option value="">Select Subject</option>
                                                {
                                                    this.state.SubjectList.map((item, i) => (
                                                        <option key={i} value={item.id}>{item.title}</option>
                                                    ))
                                                }
                                            </select>
                                            {!!this.state.subject_idError && (
                                                <text style={{ color: "#000", marginLeft: "10px" }}>
                                                    {this.state.subject_idError}
                                                </text>
                                            )} */}

                                            <select name="level" autoComplete="on" value={this.state.level} id="level" onChange={this.handleChangeLevel} className="form-control mt-1">
                                                <option value="">Select Level</option>
                                                <option value="university">University</option>
                                                <option value="school">School</option>
                                                <option value="professionals">Professional</option>
                                            </select>

                                            {/* {
                                                this.state.LevelList.length === 0 ? <select className="form-control mt-1"><option>Select Level</option></select> :
                                                    <select name="level_id" autoComplete="on" value={this.state.level_id} id="level_id" onChange={this.handleChangeLevel} className="form-control mt-1">
                                                        <option value="">Select Level</option>
                                                        {
                                                            this.state.LevelList.map((item, i) => (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            ))
                                                        }

                                                    </select>
                                            } */}
                                            {!!this.state.level_idError && (
                                                <text style={{ color: "#000", marginLeft: "10px" }}>
                                                    {this.state.level_idError}
                                                </text>
                                            )}

                                            <textarea name="message" className="form-control" value={this.state.message} onChange={(event) =>
                                                this.setState({ message: event.target.value })
                                            } autoComplete="on" className="form-control" placeholder="Message" style={{marginTop:5,paddingTop:10}}></textarea>

                                            <div className="d-flex align-items-center ">
                                                <ReCAPTCHA
                                                    sitekey="6Lea4DQpAAAAANJrP1sF9UemInf1guaRrI0I60nf"
                                                    size="invisible"
                                                    ref={this.recaptchaRef}
                                                />
                                            </div>
                                            <div className="col-sm-6 ml-auto" >
                                                <button
                                                    type="submit"
                                                    name="submit"
                                                    onClick={() => this.onSubmit()}
                                                    className="btnRegister enrolldemo"
                                                >

                                                    {this.state.isLoading ? (
                                                        <ClipLoader
                                                            type="TailSpin"
                                                            color="#ffffff"
                                                            height="20"
                                                            width="20"
                                                        />
                                                    ) : (
                                                        "Hire a tutor"
                                                    )}

                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Carousel>
                        <Carousel.Item>
                            <section id="homepage-main-new">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8 banner_margin " >
                                            <h1 className="banner-text1 home_banner_text small_text">Get Best Tutors with us</h1>
                                            <h2 className="font banner-text2">Education Capsule specializes to guide you through every topic and ensure you understand it thoroughly.

                                            </h2>
                                            <a href="/book-a-tutor" className="lending-btn" >Hire a Tutor</a>
                                        </div>
                                    </div>
                                </div>
                            </section >
                        </Carousel.Item>
                        <Carousel.Item>
                            <section id="homepage-main-new2">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8 banner_margin" >
                                            <h2 className="banner-text1 home_banner_text" >Get Stress-Free homework help</h2>
                                            <h2 className="font banner-text2">We break down complex topics and build your confidence, step by step in homework. Book a Free Consultation Today!</h2>
                                            <a href="/book-a-tutor" className="lending-btn" >Hire a Tutor</a>
                                        </div>
                                    </div>
                                </div>
                            </section >
                        </Carousel.Item>
                        <Carousel.Item>
                            <section id="homepage-main-new3">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8 banner_margin" >
                                            <h2 className="banner-text1 home_banner_text">Flexible & Convenient Learning</h2>
                                            <h2 className="font banner-text2">Study when and where it works best for you! Our online experts on our platform makes it easy to learn at your own pace.

                                            </h2>
                                            <a href="/book-a-tutor" className="lending-btn" >Hire a Tutor</a>
                                        </div>
                                    </div>
                                </div>
                            </section >
                        </Carousel.Item>
                        <Carousel.Item>
                            <section id="homepage-main-new4">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8 banner_margin" >
                                            <h2 className="banner-text1 home_banner_text">Why Choose Us for Exam Success?</h2>
                                            <h2 className="font banner-text2">We create a study schedule that works for you, focusing on your strengths and areas for improvement.</h2>
                                            <a href="/book-a-tutor" className="lending-btn" >Hire a Tutor</a>

                                        </div>
                                    </div>
                                </div>
                            </section >
                        </Carousel.Item>
                    </Carousel>
                    <ToastContainer />
                    <div className="mt-5">
                    <h2 className="largefont">Subjects we teach</h2>
                        <p className="subheading">We assess your child's abilities and style of learning and provide<br></br>personalised and adaptive one-to-one sessions</p>

                        <div>
                            {this.state.isLoading ? (
                                <p>Loading subjects...</p>
                            ) : (
                                <SearchDropdown
                                    SubjectList={this.state.SubjectList}
                                    onSubjectChange={this.handleSubjectChange}
                                    onSubmit={this.handleSubmit}
                                />
                            )}
                        </div>
                    </div>

                    <div className=" mt-4 video_none">

                        

                        <div className="row mt-5 " style={{ marginLeft: '8%', marginRight: '8%' }}>
                            <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12 deliver_sec">
                                <div className="deliver_class  ">
                                    <img src={one_to_one} alt="one_to_one" />
                                    <p className="sec_2_text" >One-to-One Personalised Live Classes</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12 deliver_sec">
                                <div className="deliver_class  ">
                                    <img src={round_clock} alt="round_clock" />
                                    <p className="sec_2_text" >Flexible & Convenient Learning</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12 deliver_sec">
                                <div className="deliver_class  ">
                                    <img src={video_lession} alt="video_lession" />
                                    <p className="sec_2_text" >Learn with Creative Video Lessons</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12 deliver_sec">
                                <div className="deliver_class  ">
                                    <img src={practice_quiz} alt="practic_quiz" />
                                    <p className="sec_2_text">Customised Practice Tests Available</p>
                                </div>
                            </div>
                        </div>
                        <div className="video_border">
                            <div className="faq_sec mt-4 mb-5 pt-5 pb-5">

                                <h2 className="largefont mt-5">Education Capsule serves</h2>
                                <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12 mt-3 text-center responsive">
                                    <LazyVideo />
                                    {/* <video className="videowidthh" width="700" poster={poster1} controls><source src={video_two} type="video/mp4" /></video> */}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="mt-5 bg-black ">
                        <div className="row">
                            <div className="col-md-4 pt-5" style={{ paddingLeft: 45 }}>
                                <div className="row">
                                    <div className="col-md-11">
                                        <div className="reviwesfont1">10000+</div>
                                        <div className="reviwesfont2">Student Enrolled</div>
                                    </div>
                                    <div className="col-md-1 mt-5 mobile_none">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                                            <path d="M1 0V63.4615" stroke="white" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 pt-5 ">
                                <div className="row">
                                    <div className="col-md-11 mt-4 text-center">
                                        {/* <h4 className="reviwesfont">Trustpilot rating</h4> */}
                                        <img src={trust_pilot} alt="star" />
                                        {/* <h4 className="reviwesfont">Reviews</h4> */}
                                    </div>
                                    <div className="col-md-1 mt-5 mobile_none">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                                            <path d="M1 0V63.4615" stroke="white" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 text-center" style={{ padding: 50 }}>
                                <img src={reviewgoogle} alt='reviews' />
                            </div>

                        </div>
                    </div>
                    <h2 className="largefont mt-4">Happy Clients</h2>
                    <div className="faq_sec mt-4 mb-5 pt-5 pb-5" style={{ marginLeft: '8%', marginRight: '8%' }}>
                        <div className="row">
                            {/* <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 col-xl-6 d-flex justify-content-center">
                                <LazyVideo3 />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 col-xl-6" style={{ padding: 32 }}> */}
                                <div class="elfsight-app-edda347b-b7da-4efa-a9e5-6cffd8b2cf9f" data-elfsight-app-lazy></div>
                            {/* </div> */}

                        </div>
                    </div>
                    <h2 className="largefont mt-4">Our customer says</h2>
                    <div className="faq_sec mt-4 mb-5 pt-5 pb-5" style={{ marginLeft: '8%', marginRight: '8%' }}>
                        
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 d-flex justify-content-center">
                            <LazyVideo3 />
                        </div>
                    </div>

                    

                    <div className="mt-3">
                        {/* <h2 className="largefont">Subjects we teach</h2>
                        <p className="subheading">We assess your child's abilities and style of learning and provide<br></br>personalised and adaptive one-to-one sessions</p>

                        <div>
                            {this.state.isLoading ? (
                                <p>Loading subjects...</p>
                            ) : (
                                <SearchDropdown
                                    SubjectList={this.state.SubjectList}
                                    onSubjectChange={this.handleSubjectChange}
                                    onSubmit={this.handleSubmit}
                                />
                            )}
                        </div> */}


                        {/* <div className="row mobile" >
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={math} alt="math" />
                                </div>
                                <h5 className="subject-title" >Mathematics</h5>
                                <p className="card-textnew" >Education Capsule has the best Math Tutors in the world. Our Maths Tutors are trusted and recommended by hundreds of parents and teachers across the UK.</p>

                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={science} alt="science" />
                                </div>
                                <h5 className="subject-title">Science</h5>
                                <p className="card-textnew" >We have a group of Science experts who are qualified enough to make learning fun and effective for kids. We help students from school to the university level.</p>

                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={english} alt="eleven" />
                                </div>
                                <h5 className="subject-title">English</h5>
                                <p className="card-textnew" >We provide everything a learner needs to become a confident and fluent English language speaker. We offer tailor-made English Language courses which include Grammar, Reading, Speaking, Listening and Writing Skills.</p>

                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={computing} alt="computing" />
                                </div>
                                <h5 className="subject-title">Computing</h5>
                                <p className="card-textnew" >Education Capsule provides one-to-one sessions on Computing up to university and higher levels. Students who learn to code and who learn about computer science can become independent adults in a technology-rich society.</p>

                            </div>
                        </div> */}

                        {/* <h2 className="largefont mt-5">Exam Preparation</h2>
                        <p className="subheading">Education Capsule provides experienced tutors, practice multi-level quizzes,and <br></br> doubt-clearing sessions to help students excel.</p>

                        <div className="row mobile" >
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={stas} alt="stas" />
                                </div>
                                <h5 className="subject-title">SATs</h5>
                                <p className="card-textnew" >We focus on minimizing the gap in the student’s knowledge level. Through effective revision classes and practice papers, we help students to excel in their SATs.</p>

                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={seven} alt="seven" />
                                </div>
                                <h5 className="subject-title">7+ Exam</h5>
                                <p className="card-textnew" >Our 7+ exam preparation course is designed to help students to achieve success in their exams. Our tutors are well-versed in the requirements of the 7+ exam.</p>

                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={eleven} alt="eleven" />
                                </div>
                                <h5 className="subject-title">11+ Exam</h5>
                                <p className="card-textnew " >Education Capsule has a strong performance history when it comes to the 11+ Exam. We are specialists in this field and provide personalised one on one classes</p>

                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={thirteen} alt="thirteen" className="sliderimage" />
                                </div>
                                <h5 className="subject-title">13+ Exam</h5>
                                <p className="card-textnew " >Every tutor we recommend has outstanding experience and a proven track record in helping children excel in 13+ exams. The sessions are tailored to the pupil’s level of understanding to provide excellent support. </p>
                            </div>
                        </div> */}

                        <h2 className="largefont mt-4"  >Start your learning journey with us</h2>
                        <div className="row mt-5">
                            <div className="col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12  text-center">
                                <img src={signup} alt="sign Up" />
                                <br></br>
                                <div style={{ display: 'flex' }}>
                                    <a href="/register" className="lending-btn bookdemo" > Sign Up</a>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12 text-center" >
                                <img src={request_demo} alt="Request Demo" /><br></br>
                                <div style={{ display: 'flex' }}>
                                    <a href="/book-a-tutor" className="lending-btn bookdemo" > Request Lesson</a>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12  text-center">
                                <img src={start_session} alt="start lesson" /><br></br>
                                <div style={{ display: 'flex' }}>
                                    <a href="/book-a-tutor" class="lending-btn bookdemo">Start Lesson</a>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className="mt-5 bckgrngimg video_none">
                        <div className="mt-5 pt-4">
                            <h2 className="text-center howdowework_font">Why Choose Education Capsule?</h2>
                        </div>

                        <div className="mt-5">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12 text-center">
                                    <LazyVideo2 />
                                </div>
                                <div className="mt-3" style={{ paddingLeft: '8%', paddingRight: '8%' }}>
                                    <h3 className="howdowework_font text-center">We support for all subjects upto all levels with safe & comfortable learning environment.Learning from home allows students to study in a comfortable, quiet environment where they can focus better and reduce distractions.</h3>
                                </div>
                                <div className="col-md-2 col-lg-2 col-xl-2 col-sm-12 col-xs-12">
                                </div>
                            </div>
                        </div>
                    </div> */}

<div className="container position-relative my-5">
<FAQComponent />
</div>

                </ >
            )
        } else {
            if (roleId == 3) {
                window.location.href = Constant.BaseUrl + "student-dashboard"
            } else {
                window.location.href = Constant.BaseUrl + "tutor-dashboard"
            }
        }

    }
}

function SearchDropdown({ SubjectList, onSubjectChange, onSubmit }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredSubjects, setFilteredSubjects] = useState([]);

    useEffect(() => {

        if (searchTerm) {
            const filtered = SubjectList.filter(subject =>
                subject.title.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredSubjects(filtered);
        } else {
            setFilteredSubjects([]);
        }
    }, [searchTerm, SubjectList]);

    const handleSelect = (subject) => {
        setSearchTerm(subject.title);
        onSubjectChange(subject.id);
        setFilteredSubjects([]);
    };

    return (
        <div className="container position-relative">
            <div className="row justify-content-center align-items-center">
                <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Subject"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {filteredSubjects.length > 0 && (
                        <ul className="dropdown-menu show" style={{ width: "31%" }}>
                            {filteredSubjects.map((subject) => (
                                <li
                                    key={subject.id}
                                    className="dropdown-item"
                                    onMouseDown={() => handleSelect(subject)} // onMouseDown to prevent focus loss
                                >
                                    {subject.title}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="col-md-2">
                    <button onClick={onSubmit} className="lending-btn">Search</button>
                </div>
            </div>
        </div>
    );
}

function SearchDropdown2({ SubjectList, onSubjectChange }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredSubjects, setFilteredSubjects] = useState([]);

    useEffect(() => {
        if (searchTerm) {
            const filtered = SubjectList.filter(subject =>
                subject.title.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredSubjects(filtered);
        } else {
            setFilteredSubjects([]);
        }
    }, [searchTerm, SubjectList]);

    const handleSelect = (subject) => {
        setSearchTerm(subject.title); // Set the input field to the subject's title
        onSubjectChange(subject.id);  // Notify the parent component of the subject change
        setFilteredSubjects([]);     // Clear the dropdown after selection
    };

    return (
        <div className="container position-relative">
            <div className="row">
                <div className="col-md-12" style={{ padding: 0 }}>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Subject"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {filteredSubjects.length > 0 && (
                        <ul className="dropdown-menu show" style={{ width: "100%" }}>
                            {filteredSubjects.map((subject) => (
                                <li
                                    key={subject.id}
                                    className="dropdown-item"
                                    onMouseDown={() => handleSelect(subject)} // Use onMouseDown to prevent focus loss
                                >
                                    {subject.title}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
}


export default Homepagenew;
