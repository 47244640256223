import React from "react";
import "./App.css";
import "./assets/css/responsive.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import "@fontsource/bubblegum-sans";
import 'react-toastify/dist/ReactToastify.css';

//layouts
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";

//component
import Homepagenew from "../src/components/Homepagenew";
import LoginPage from "../src/components/LoginPage";
import Registration from "./components/Registration";
import ContactUs from "./components/ContactUs";
import VerifyEmail from "./components/VerifyEmail";

//Tutor pages
import ReportsDataTutor from "./components/ReportsDataTutor";
import UploadHomeworkTutor from "./components/dashboard/UploadHomeworkTutor";
import TutorFeedback from "./components/TutorFeedback";
import TutorRegistration from "./components/TutorRegistration";
import TutorDashboard from "./components/dashboard/TutorDashboard";
import TutorProfile from "./components/dashboard/TutorProfile";
import OtpScreen from "./components/dashboard/OtpScreen";

//footer
import TermsAndCondition from "./components/TermsAndCondition";
import SufegaurdingPolicy from "./components/SufegaurdingPolicy";
import WhistleBlowingPolicy from "./components/WhistleBlowingPolicy";
import PrivacyPolicy from "./components/PrivacyPolicy";

//subject pages
import Maths from './components/subjects/Maths'
import Science from './components/subjects/Science'
import Computing from './components/subjects/Computing'
import English from './components/subjects/English'
import Punjabi from './components/subjects/Punjabi'
import SubjectDetails from './components/subjects/SubjectDetails'
import SubjectDetailsNew from './components/subjects/SubjectDetailsNew'
import TopicsDetails from "./components/subjects/TopicsDetails";
import TopicDetailsNew from "./components/subjects/TopicDetailsNew";
import Payment from "./components/subjects/Payment";
import PaymentSuccess from "./components/subjects/PaymentSuccess";
import ExamPaper from "./components/subjects/ExamPaper";
import QuestionPaper from "./components/subjects/QuestionPaper";

//services page
import ExamPreparation from "./components/ExamPreparation";
import ExamPrep from "./components/services/ExamPreparation";
import CurriculamPage from "./components/services/CurriculamPage";
import HomeWorkHelp from "./components/services/HomeWorkHelp";
import BookOnlineExport from "./components/services/BookOnlineExport";
import EducationalAdvice from "./components/services/EducationalAdvice";
import SpecialEducation from "./components/services/SpecialEducation";

//About page
import HowWeWork from './components/about/HowWeWork'
import OurApproach from './components/about/OurApproach'
import Testimonials from './components/about/Testimonials'
import Membership from './components/about/Membership'

//student pages
import StudentDashboard from "./components/dashboard/StudentDashboard";
import StudentProfile from "./components/dashboard/StudentProfile";
import Wallet from "./components/Wallet";
import StudentFeedback from "./components/StudentFeedback";
import StudentRegistration from "./components/StudentRegistration";
import ReportsData from "./components/ReportsData";
import BookSession from "./components/BookSession";
import BookATutor from "./components/BookATutor";

import Thankyou from "./components/ThankYou";
import ThankyouFeedback from "./components/ThankyouFeedback";
import ThankYouRegistration from "./components/ThankYouRegistration";
import ThankYouContact from "./components/ThankYouContact";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";

import Blog from "./components/Blog";
import BlogDetails from "./components/BlogDetails";

import 'react-chat-widget/lib/styles.css';


function App() {


  return (
    <div className="App">
      <Header />
      <Routes>
        <Route exact path="/" element={<Homepagenew />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/register" element={<Registration />} />
        <Route
          exact
          path="/student-register"
          element={<StudentRegistration />}
        />

        {/* tutor routes */}
        <Route exact path="/tutor-register" element={<TutorRegistration />} />
        <Route exact path="/upload-homework-tutor" element={<UploadHomeworkTutor />} />
        <Route exact path="/tutor-feedback" element={<TutorFeedback />} />
        <Route exact path="/report-data" element={<ReportsDataTutor />} />
        <Route exact path="/tutor-dashboard" element={<TutorDashboard />} />
        <Route exact path="/tutor-profile" element={<TutorProfile />} />

        <Route exact path="/otp" element={<OtpScreen />} />
        <Route exact path="/contact-us" element={<ContactUs />} />

        {/* Subject Routes */}
        <Route exact path="/Math" element={<Maths />} />
        <Route exact path="/Science" element={<Science />} />
        <Route exact path="/Computing" element={<Computing />} />
        <Route exact path="/English" element={<English />} />
        <Route exact path="/Punjabi" element={<Punjabi />} />
        <Route exact path="/subject-details/:id/" element={<SubjectDetails />} />
        <Route exact path="/show-details/:id" element={<SubjectDetailsNew />} />
        <Route exact path="/topic-details/:cid/:id" element={<TopicsDetails />} />
        <Route exact path="/topic-details-new/:id" element={<TopicDetailsNew />} />
        <Route exact path="/payment/:id" element={<Payment />} />
        <Route exact path="/payment-success" element={<PaymentSuccess />} />

        {/* Services Routes */}
        <Route exact path="/exam-prep" element={<ExamPrep />} />
        <Route exact path="/curriculm-page" element={<CurriculamPage />} />
        <Route exact path="/homework-help" element={<HomeWorkHelp />} />
        <Route exact path="/book-online-expert" element={<BookOnlineExport />} />
        <Route exact path="/educational-advice" element={<EducationalAdvice />} />
        <Route exact path="/special-education" element={<SpecialEducation />} />

        {/* About Routes */}
        <Route exact path="/how-we-work" element={<HowWeWork />} />
        <Route exact path="/our-approach" element={<OurApproach />} />
        <Route exact path="/membership" element={<Membership />} />
        <Route exact path="/testimonials" element={<Testimonials />} />

        {/* footer Routes */}
        <Route exact path="/book-a-tutor" element={<BookATutor />} />
        <Route exact path="/terms-and-conditions" element={<TermsAndCondition />} />
        <Route exact path="/safeguarding-policy" element={<SufegaurdingPolicy />} />
        <Route exact path="/whistleblowing-policy" element={<WhistleBlowingPolicy />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* student Routes */}
        <Route exact path="/student-dashboard" element={<StudentDashboard />} />
        <Route exact path="/my-profile" element={<StudentProfile />} />
        <Route exact path="/thank-you" element={<Thankyou />} />
        <Route exact path="/verify-email/:id" element={<VerifyEmail />} />
        <Route exact path="/book-session" element={<BookSession />} />
        <Route exact path="/wallet" element={<Wallet />} />
        <Route exact path="/student-feedback" element={<StudentFeedback />} />
        <Route exact path="/reports-data" element={<ReportsData />} />

        {/* Thanks You Routes */}
        <Route exact path="/thank-you-feedback" element={<ThankyouFeedback />} />
        <Route exact path="/thank-you-register" element={<ThankYouRegistration />} />
        <Route exact path="/thank-you-contact" element={<ThankYouContact />} />
        <Route exact path="/forgot-passowrd" element={<ForgotPassword />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />

        {/* Exam Preparation */}
        <Route exact path="/exam_papers/:sid/:pid" element={<ExamPaper />} />
        <Route exact path="/question_papers/:id" element={<QuestionPaper />} />
        <Route exact path="/exam-preparation" element={<ExamPreparation />} />

        <Route exact path="/blogs" element={<Blog />} />
        <Route exact path="/blog_details/:slug" element={<BlogDetails />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
