import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const FAQComponent = () => {
    const [activeTab, setActiveTab] = useState(null);

    const faqData = [
        { id: 'tabone', question: 'What services does education capsule provide?', answer: 'Education Capsule is an online tutoring platform with qualified tutors. We provide one-to-one personalized tutoring from the school level to the university level, covering a wide range of subjects and preparation for standardized tests like SATs, GCSE, 11+, 13+, and A-levels.' },
        { id: 'tabtwo', question: 'How do I get started?', answer: 'Sign up using the ‘Join Now’ button and submit the ‘Book Lesson Now’ form to book your free demo lesson with Education Capsule. Once completed, you can connect with us via our contact page to proceed further.' },
        { id: 'tabthree', question: 'What subjects do you offer tutoring in?', answer: 'We offer tutoring in a broad range of subjects including Mathematics, Science, English, History, and various languages. We also provide specialized tutoring for tests preparation and advanced subjects at the university level.' },
        { id: 'tabfour', question: 'How does education capsule work?', answer: 'Upon registering, we start with a pre-assessment to understand the student’s needs, and then tailor lessons accordingly. We use interactive tools and methods to engage students and enhance learning, with continuous feedback and adjustment to ensure optimal learning outcomes.' },
        { id: 'tabfive', question: 'Can you accommodate flexible tutoring schedules?', answer: 'Yes, our platform is designed to accommodate flexible schedules to meet the diverse needs of our students. Tutors are available around the clock, allowing students to book sessions at times that suit them best, including weekends and holidays.' }
    ];

    const toggleTab = (id) => {
        setActiveTab(activeTab === id ? null : id);
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2 className="text-center mb-4">Frequently Asked Questions</h2>
                    <div className="accordion" id="faqAccordion">
                        {faqData.map((item, index) => (
                            <div className="" key={index} style={{ marginBottom: '1rem' }}>
                                 <div className="card-header" id={`heading${index}`}>
                                    <h2 className="mb-0">
                                        <button className="btn btn-link btn-block text-left d-flex justify-content-between align-items-center" type="button"
                                                onClick={() => toggleTab(item.id)}
                                                aria-expanded={activeTab === item.id}
                                                aria-controls={`collapse${index}`} style={{width: '100%'}}>
                                            <span className="text-left">{item.question}</span>
                                            <span className="arrow" style={{ transform: activeTab === item.id ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                                                &#9662; {/* Downward arrow Unicode character */}
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id={`collapse${index}`} className={`collapse ${activeTab === item.id ? 'show' : ''}`}
                                     aria-labelledby={`heading${index}`}>
                                    <div className="card-body">
                                        {item.answer}
                                        {/* <Link to="/contact-us" className="d-block mt-3">Learn more</Link> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQComponent;
