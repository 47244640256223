import React, { useState, useEffect } from 'react'
import homework from '../assets/img/services/new/homework.png'
import * as Constant from './Constant'
import { Link } from 'react-router-dom';
import "./Blogs.css"

export default function Blog() {
    const [blogs, setBlogs] = useState([]); // State to store blog data
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const fetchBlogs = async () => {
          try {
            // Make the fetch request to the API
            const response = await fetch(Constant.Blogs);
            
            if (!response.ok) {
              throw new Error('Failed to fetch data');
            }
    
            const data = await response.json(); // Parse the response as JSON
    
            // Check if the API response is successful and contains 'data'
            if (data.success && Array.isArray(data.data)) {
              setBlogs(data.data); // Set blogs data in the state
            } else {
              console.error('Error: No valid blog data found');
            }
          } catch (error) {
            console.error('Error fetching blog data', error);
          } finally {
            setLoading(false); // Set loading to false after data is fetched or error occurs
          }
        };
    
        fetchBlogs(); // Call the fetchBlogs function
      }, []); // Empty dependency array ensures this runs only once after initial render
    
      if (loading) {
        return <div>Loading...</div>; // Display loading message while fetching data
      }
    
  return (
    <>
    <div>
    <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '400px',background:'#F8FAFC' }}>
          <h1 className="display-3 font-weight-bold">Blogs</h1>
          <div className="d-inline-flex text-white">
            <p className="m-0">
              <Link className="text-dark" to="/">
                Home
              </Link>
            </p>
            <p className="m-0 px-2">/</p>
            <p className="m-0 text-dark">Blog</p>
          </div>
        </div>
      
        <div className="container ">
          <div className='blog-list'>
            <div className='row'>
                {blogs.map((blog, index) => (
                  <div className="col-md-8 col-lg-8 col-xl-8 col-sm-12 col-xs-12 mx-auto">
                  <div key={index} className='blog-card'>
                    <a href={`/blog_details/${blog.slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <div className="blog-image">
                        <img src={blog.image} alt={blog.title} className='rounded' />
                      </div>
                      <h3>{blog.title}</h3>
                      <p className='truncate-description'> {blog.description}</p>
                    </a>
                    <div className="d-flex justify-content-center mt-4">
                      <a href={`/blog_details/${blog.slug}`} className='lending-btn'>
                        Read More
                      </a>
                    </div>
                  </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        </div>
    </>
  )
}
