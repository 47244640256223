import React, { useRef, useEffect } from 'react';
import video_three from '../assets/videos/testimonialsfinal.mp4';
import poster3 from '../assets/img/newhomepage/testimonialsfinal.png';

const LazyVideo3 = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    videoRef.current.play();
                } else {
                    videoRef.current.pause();
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5  // More appropriate threshold for starting the video
        });

        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };
    }, []);

    return (
        <div className="video-container">
            <video ref={videoRef} className="responsive-video" poster={poster3} controls>
                <source src={video_three} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <p className="video-caption">Hear what our customers have to say</p>
        </div>
    );
};

export default LazyVideo3;
