export const BaseUrlApi = "https://admin.educationcapsule.com/api/"
// export const BaseUrlApi = "http://localhost/educationcapsule/api/"

export const RegisterUrl = BaseUrlApi + 'register'

export const TutorRegisterUrl = BaseUrlApi + 'tutor_register'

export const verifyEmailUrl = BaseUrlApi + 'verify-email'

export const LoginUrl = BaseUrlApi + 'login'

export const BookSession = BaseUrlApi + 'booksession'

export const ContactUs = BaseUrlApi + 'contactus'

export const FreeDemo = BaseUrlApi + 'freedemo'

export const getCountry = BaseUrlApi + 'getcountrylist'

export const getTimeZone = BaseUrlApi + 'gettimezonelist'

export const getSubject = BaseUrlApi + 'getsubjectlist'

export const getLevels = BaseUrlApi + 'getlevelslist'

export const getSubjectLevels = BaseUrlApi + 'subject-level'

export const getSubLevels = BaseUrlApi + 'getsublevel'

export const getProfileLevel = BaseUrlApi + 'get_levels'

export const getSelectedSubLevel = BaseUrlApi + 'get_sublevels'

export const getTopics = BaseUrlApi + 'get-topic'

export const getChapters = BaseUrlApi + 'getchapter'

export const getTopicContent = BaseUrlApi + 'get-topic-content'

export const getUserProfile = BaseUrlApi + 'getstudentprofile'

export const editUserProfile = BaseUrlApi + 'editprofile'

export const editSessionRequest = BaseUrlApi + 'edit-request'

export const getSession = BaseUrlApi + 'get-session'

export const getScheduledSession = BaseUrlApi + 'getscheduled-session'

export const getUpcomingSession = BaseUrlApi + 'getupcoming-session'

export const getTomorrowSession = BaseUrlApi + 'gettomorrow-session'

export const getTutorSession = BaseUrlApi + 'gettutorsession'

export const getTutorTomorrowSession = BaseUrlApi + 'gettutortomorrow-session'

export const getTutorUpcomingSession = BaseUrlApi + 'gettutorupcoming-session'

export const getTutorCompSession = BaseUrlApi + 'tutorcompsession'

export const getCompletedSession = BaseUrlApi + 'getcompletedsession'

export const getLastSession = BaseUrlApi + 'get_last_session'

export const studentFeedback = BaseUrlApi + 'studentfeedback'

export const tutorFeedback = BaseUrlApi + 'tutorfeedback'

export const sessionReport = BaseUrlApi + 'sessionreport'

export const sessionReportTutor = BaseUrlApi + 'sessionreporttutor'

export const bookTutor = BaseUrlApi + 'booktutor'

export const bookOnlinEexport = BaseUrlApi + 'bookonlineexport'

export const onCloseSession = BaseUrlApi + 'close-session'

export const uploadHomeWorkTutor = BaseUrlApi + 'upload_homework'

export const homeworkStudent = BaseUrlApi + 'show_homework'

export const homeworkTutor = BaseUrlApi + 'show_homework_tutor'

export const submitHomeWork = BaseUrlApi + 'submit_homework'

export const getQuiz = BaseUrlApi + 'get_quiz'

export const getQuizTitle = BaseUrlApi + 'getquiztitle'

export const resetPassword = BaseUrlApi + 'password/reset'

export const forgotPassword = BaseUrlApi + 'forgot-password'

export const quizScore = BaseUrlApi + 'quiz-score'

export const moreUserSubject = BaseUrlApi + 'more_user_subject'

export const getMembership = BaseUrlApi + 'get_membership'

export const getPayMembership = BaseUrlApi + 'get_pay_membership'

export const getUserMoreSub = BaseUrlApi + 'get_more_subject'

export const getPapers = BaseUrlApi + 'get_paper'

export const getExamPapers = BaseUrlApi + 'get_exam_paper'

export const getQuePapers = BaseUrlApi + 'get_que_paper'

export const getQuestions = BaseUrlApi + 'get_questions'

export const getPayment = BaseUrlApi + 'payment-details'

export const homeWorkFile = BaseUrlApi + 'homework_file'

export const homeWorkFileStudent = BaseUrlApi + 'homework_file_student'

export const googleLogin = BaseUrlApi + 'google/callback'

export const emailExist = BaseUrlApi + 'email_exist'

export const Blogs = BaseUrlApi + 'blog'

export const BlogDetails = BaseUrlApi + 'blog_details'

// export const BaseUrl = "https://stagging.educationcapsule.com/"
export const BaseUrl = "https://educationcapsule.com/"  
// export const BaseUrl = "http://localhost:3000/"